import React from 'react';

const PageBanner = ({ title }) => {
    return(
        <div className="section pagebanner">
            <div className="overlay">
                <div className="innerwrap">
                    <h1>{title}</h1>
                </div>
            </div>
        </div>
    )
}

export default PageBanner;