import React from 'react';
import PageBanner from '../components/pagebanner';
import Layout from '../layouts/main';
import Slider from "react-slick";

import campus from '../assets/images/campus.jpg';
import SEO from '../components/seo';
// import avatar from '../assets/images/avatar.jpg';

const AboutUs = () => {
	const settings = {
		autoplay: true,
		dots: false,
		slidesToShow: 5,
	};

	return (
		<Layout>
			<SEO title="About Us" />
			<PageBanner title="About SavNƏT" />
			<div className="section aboutus">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-6 grid-sm-12 grid-xs-12">
							<div className="section-title">
								<span>About Us</span>
								<h2>Building Skills, Buidling Future</h2>
							</div>
							<div className="section-content">
								<p>SavNƏT is an Australian Company founded by an experienced IT Professional from India, who migrated to Australia after spending his time in India and is in the industry from last 20 years. Our vision is to provide training to students at low cost and ensure they make their career in IT and grow. At SavNƏT we know how to get you into IT and shape your career.</p>

							</div>
						</div>
						<div className="grid-6 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<div className="imageholder">
									<img src={campus} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="why-us" className="section objective">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-8 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<h2>Objective:</h2>
								<p>Recently Covid-19 has affected the education of lot of students and career of the professionals.
									The recent generation has lost almost 2 years of education due to Covid-19. The objective of
									the SavNƏT is to give back to the society, As the founder himself learnt from India and worked
									in India and then migrated to Australia, his mission is the same to the students learning here, we
									are not focusing on one skill, one course, the focus is to develop the IT skills in the field of
									Software development, IT infrastructure, such as Network and the cloud, Communication skills,
									Personality Development, as it is very important to acquire all the skills and then go to the
									market. All Skills, one place, one Fee !</p>

							</div>
						</div>
						<div className="grid-4 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<div className="immageholder"></div>

							</div>
						</div>
					</div>
					<div className="row">
						<div className="grid-4 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<div className="immageholder"></div>

							</div>
						</div>
						<div className="grid-8 grid-sm-12 grid-xs-12">
							<div className="section-content">

								<h2>Employment:</h2>
								<p>Our Focus is to train the youth who is pursuing a graduation, or has completed his graduation or
									someone already working but wants to grow and get into the IT field, If you are already working
									in another industry but wants to get into IT, then we are the right place for you. Building skills
									with the personality development will land you to the right place, our focus is to train you the
									industry standards and that’s the reason we have combined all courses, we will mentor you for
									the interviews so that you are industry ready and ahead of the others by acquiring the multiple skills, our focus is not just to train but also to ensure you get the Job before or after completion
									of your course.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="grid-8 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<h2>Skills Development</h2>
								<p>Our focus is to ensure developing your skills, once you enroll, you are not limited to one course
									but the entire courses we offer, as the need in the market has grown so as the skills
									requirement has grown, for that we will offer every support you need and we help you to build
									up the required skills and develop the right attitude to secure your job and grow into your career.</p>
								<p>At the end of your course, you will receive the course completion certificate which will list all the
									courses you have pursued, which you can show into your resume that you are not a one skill
									professional but have the multiple skills.</p>

							</div>
						</div>
						<div className="grid-4 grid-sm-12 grid-xs-12">
							<div className="section-content">
								<div className="immageholder"></div>

							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div id="why-us" className="section features aboutus">
				<div className="innerwrap">
                    <div className="row">
                        <div className="grid-12">
                            <div className="section-title">
                                <span>Why Choose Us</span>
                                <h2>Why You Should Choose SavNƏT</h2>
                            </div>
                        </div>
                    </div>
					<div className="row">
						<div className="grid-3">
							<div className="feature-card first">
								<ion-icon name="library-outline"></ion-icon>
								<h3>Education</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
						<div className="grid-3">
							<div className="feature-card second">
							<ion-icon name="newspaper-outline"></ion-icon>
								<h3>Professional</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
						<div className="grid-3">
							<div className="feature-card third">
							<ion-icon name="hourglass-outline"></ion-icon>
								<h3>Research</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
						<div className="grid-3">
							<div className="feature-card fourth">
							<ion-icon name="calendar-outline"></ion-icon>
								<h3>Management</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
                        <div className="grid-3">
							<div className="feature-card first">
								<ion-icon name="library-outline"></ion-icon>
								<h3>Education</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
						<div className="grid-3">
							<div className="feature-card second">
							<ion-icon name="newspaper-outline"></ion-icon>
								<h3>Professional</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
						<div className="grid-3">
							<div className="feature-card third">
							<ion-icon name="hourglass-outline"></ion-icon>
								<h3>Research</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
						<div className="grid-3">
							<div className="feature-card fourth">
							<ion-icon name="calendar-outline"></ion-icon>
								<h3>Management</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div id="director-message" className="section directors">
				<div className="innerwrap">
					<div className="row">
						{/* <div className="grid-5 grid-sm-12 grid-xs-12">
							<img src={avatar} className="directors-avatar" />

						</div> */}
						<div className="grid-12 grid-sm-12 grid-xs-12">
							<div className="directors-message">
								<div className="messagehere">
									<h2>Directors Message:</h2>
									{/* <span>Director</span> */}
									<p>After Covid-19 it is evident that in future the trend would be to increase the work from home job,
										which is easy when you join the IT industry, I believe everyone can join the IT Industry if right
										guidance is provided, at SavNƏT we don’t work as traditional institute approach but we work as
										a mentor towards the students and ensure they learn all the required skills and shape their
										career, with the technical education we also work towards the overall development of the
										student so that they can not only work in India but in future they will be equipped enough to go
										to the other countries.</p>
									<p>The mode of the teaching would be online and if you don’t secure your job after completing your
										course, you can again repeat it, our main motto is to make every student skillful so they can
										stand apart from the crowd and can work in the multinational companies.</p>
									{/* <ul className="social">
                                <li><a href="#"><ion-icon name="logo-facebook"></ion-icon></a></li>
                                <li><a href="#"><ion-icon name="logo-twitter"></ion-icon></a></li>
                                <li><a href="#"><ion-icon name="logo-instagram"></ion-icon></a></li>
                                <li><a href="#"><ion-icon name="logo-youtube"></ion-icon></a></li>
                            </ul> */}
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			{/* <div id="faculty" className="section">
				<div className="innerwrap">
					<div className="row">
						<div className="grid-12">
							<div className="section-title">
								<span>Experts</span>
								<h2>Our Team</h2>
							</div>
							<div className="section-content">
								<div className="achievers-list">
								<Slider className="achievers-list-slider" {...settings}>
									{[1,2,3,4,5,6,7,8,9].map((item, index) => (
										<div key={index} className="achievers-item">
											<div className="avatar">
												<img src={avatar}  />
											<span className="ribbon"><ion-icon name="ribbon-outline"></ion-icon></span>
											</div>
											<div className="content">
												<h4>Full Name</h4>
												<span>Profile</span>
											</div>
										</div>
									))}
								</Slider>
								</div>
								<div className="achievers-action"><a href="#">Read More <ion-icon name="chevron-forward-outline"></ion-icon> </a></div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

		</Layout>
	)
}

export default AboutUs;